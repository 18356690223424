@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300&display=swap");

body {
    font-family: "IBM Plex Sans", sans-serif !important;
}

.navbar-light .navbar-nav .nav-link {
    color: black !important;
}

.navbar-light .navbar-nav .nav-link:hover {
    color: gray !important;
}

.navbar-light .navbar-nav .nav-link a:visited {
    color: black !important;
}

.fa-heart {
    color: darkred;
    font-size: 14px !important;
}

hr {
    border-top: 1px solid rgba(0, 0, 0, 0.2) !important;
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
}

.no-border {
    border: none !important;
}

.card-img-top:hover {
    cursor: pointer;
}

.react-images__view-image {
    max-width: 95vw !important;
    max-height: 95vh !important;
}

.screen-height {
    min-height: 100vh;
    min-width: 100vw;
}

.error {
    color: darkred;
}

.logo, .button-styled {
    max-height: 30vh;
    margin: auto;
    /* animation: fadeIn ease 5s;
    -webkit-animation: fadeIn ease 5s;
    -moz-animation: fadeIn ease 5s;
    -o-animation: fadeIn ease 5s;
    -ms-animation: fadeIn ease 5s; */
}
/* @keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
} */

.intro-row {
    position: absolute;
    top: 30vh;
    left: 0;
    right: 0;
    margin: none;
}

/* .intro {
    background-image: url("/intro.jpg");
    background-position: center;
    background-size: cover;
    height: 100vh;
} */

#intro-video {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%; 
    min-height: 100%;
    object-fit: cover;
  }

.terms {
    height: 50vh;
    overflow: auto;
    border: 1px solid black;
}

.social {
    display: inline !important;
}

@media (max-width: 768px) {
    .social {
        margin-right: 1rem;
    }
}

@media (orientation: portrait) {
    .intro {
        background-image: url("/intro_v.jpg");
        background-position: center;
        background-size: cover;
        height: 100vh;
    }
}

.button-styled {
    --b: 3px;   /* border thickness */
    --s: .45em; /* size of the corner */
    --color: black;
    
    padding: calc(.5em + var(--s)) calc(.9em + var(--s));
    color: var(--color);
    --_p: var(--s);
    /* background:
      conic-gradient(from 90deg at var(--b) var(--b),#0000 90deg,var(--color) 0)
      var(--_p) var(--_p)/calc(100% - var(--b) - 2*var(--_p)) calc(100% - var(--b) - 2*var(--_p)); */
    transition: .3s linear, color 0s, background-color 0s;
    outline: var(--b) solid #0000;
    outline-offset: .6em;
    font-size: 16px;
    opacity: 0.7;
    background-color: white;
    border: 0;
  
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }
  
  .button-styled:hover,
  .button-styled:focus-visible{
    --_p: 0px;
    outline-color: var(--color);
    outline-offset: .05em;
  }
  
  .button-styled:active {
    background: var(--color);
    color: #fff;
  }

  .react-photo-gallery--gallery .item-container {
    position: relative;
    /* width: 50%; */
  }
  
  .react-photo-gallery--gallery img, .react-photo-gallery--gallery video {
    opacity: 1;
    /* display: block; */
    /* width: auto; */
    /* height: auto; */
    transition: .5s ease;
    backface-visibility: hidden;
  }
  
  .caption {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
    visibility: hidden;
    width: 80%;
  }
  
  .item-container:hover img, .item-container:hover video {
    opacity: 0.3;
    cursor: pointer;
  }
  
  .item-container:hover .caption {
    opacity: 1;
    visibility: visible;
    cursor: pointer;
  }
  
  .text {
    background-color: transparent;
    color: black;
    font-size: 14px;
  }

  .caption {
    display: none;
  }
  